import { NavDropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { DropdownItemProps } from "react-bootstrap/esm/DropdownItem";


// Props interface
interface MenuElement extends DropdownItemProps {
  divider?: boolean;
  onClick?: () => void;

}

interface Props {
  menuContent: MenuElement[]
};

export function MenuDropDown(props: Props) {
  return (
    <>
      <NavDropdown
        title={
          <>
            <FaUserCircle size={30} />
          </>
        }
        id="collasible-nav-dropdown"
      >
        { props.menuContent.map((item, index) => {
            return (
              item.divider ? 
                <NavDropdown.Divider key={ index } /> : 
                <NavDropdown.Item {...item} key={ index }>
                  { item.title }
                </NavDropdown.Item>
            )
          })
        }
    </NavDropdown>
    </>
  );
}
