// DUCKS pattern

import { Room } from 'app/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState : {
    roomList : Room[]
} = {
    roomList : [],
}

export const roomListSlice = createSlice({
    name: 'roomList',
    initialState,
    reducers: {
        roomListUpdated: (state, action: PayloadAction<Room[]>) => {
            // state = { ...state, id: action.payload };
            state.roomList = action.payload;
        }
    }
});

export const { roomListUpdated } = roomListSlice.actions;

export default roomListSlice.reducer;

