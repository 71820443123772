// DUCKS pattern

import { Room } from 'app/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from 'app/store';

const initialState: Room = {
        id: "",
        short_name: "",
};

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        roomIdUpdated: (state, action: PayloadAction<Room>) => {
            // state = { ...state, id: action.payload };
            state.id = action.payload.id;
            state.short_name = action.payload.short_name;
        }
    }
});

export const { roomIdUpdated } = roomSlice.actions;

export default roomSlice.reducer;

