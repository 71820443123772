import { useEffect, useState } from "react";
import { Container, Card, Button, Offcanvas } from "react-bootstrap";
import { Room } from "app/types";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaCog, FaWindowClose } from "react-icons/fa";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { supabase } from "app/supabaseClient";
import { deleteAllRoomsExceptCurrent, updateRoomList } from "actions/room-list";
import { store } from "app/store";
import { RoomList } from "./RoomList";


// MUST REVIEW
// NO DETAILED SUPABASE CODE SHOULD BE HERE. NEED MORE ABSTRACTION.
// RELY ON REDUX INSTEAD ?

export function RoomBrowser() {
  const [show, setShow] = useState(false);
  const userKind = useAppSelector((state) => state.user.userKind);
  const roomList = useAppSelector((state) => state.roomList.roomList);

  const dispatch = useAppDispatch();

  async function listenForRoomsChanges() {
    supabase
      .channel("table-db-changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "rooms",
        },
        async () => { dispatch(updateRoomList()) }
      )
      .subscribe();
  }


  useEffect(() => {
    console.debug("Room browser mounted");

    // Get rooms from database, add their id to the room object, and set them to state
    console.debug("  - Updating room list from db");
    dispatch(updateRoomList());

    // Listen for changes to rooms in database, and update state
    console.debug("  - Subscribing to room updates");
    listenForRoomsChanges();
  }, []);

  // Delete room from database
  async function deleteAllRoomsAlert() {
    confirmAlert({
      title: "Delete all rooms",
      message: "Are you sure you want to delete all rooms?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("TEST");
            dispatch(deleteAllRoomsExceptCurrent());
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }


  return (
    <Container>
      {userKind === "practitioner" ? (
        <>
          <div className="d-flex flex-row-reverse">
            <Button onClick={() => setShow(true)} variant="outline-secondary">
              <FaCog color="Black" size={"20px"} />
            </Button>
          </div>
          <Offcanvas show={show} placement="end" backdrop={false} scroll={true}>
            <Card>
              <Card.Header>
                <h2>
                  <FaWindowClose
                    size={"25px"}
                    className="me-3 hover-pointer"
                    onClick={() => setShow(false)}
                  />
                  Manage Rooms
                </h2>
              </Card.Header>
              <Card.Body>
                <RoomList rooms={ roomList } />
              </Card.Body>
              <Card.Footer>
                <Button
                  variant="danger"
                  className="mx-2"
                  onClick={deleteAllRoomsAlert}
                >
                  Delete all rooms
                </Button>
                {/* <Button variant="primary" className="mx-2" onClick={updateRoomListGroupFromDb}>Refresh</Button> */}
              </Card.Footer>
            </Card>
          </Offcanvas>
        </>
      ) : null}
    </Container>
  );
}
