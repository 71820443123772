import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Room } from "app/types";
import "firebase/firestore";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaPhone } from "react-icons/fa";

import { roomIdUpdated } from "features/room/room-slice";

import { useAppDispatch } from "app/hooks";
import { findRoomWithId } from "actions/room-list";
interface RoomListProps {
    rooms: Array<Room>;
}



export function RoomList(props: RoomListProps) {
    const dispatch = useAppDispatch();

    function setCurrentRoom(roomId: string) {
        console.debug("Clicked on call button for room " + roomId);
        dispatch(findRoomWithId(roomId));
    }

    return (
        <ListGroup className="mb-2" style={{ maxHeight: "20vh", overflowY: "scroll" }}>
            {props.rooms.length > 0 ?
                props.rooms.map((room, index) => {
                    return (
                        <ListGroupItem key={room.id} variant={(index % 2) ? "" : "secondary"} className="hover-pointer" onClick={
                            () => { setCurrentRoom(room.id) }
                        }>
                            <FaPhone size={"20px"} className="me-3" color="DarkGreen" />
                            {room.short_name}
                        </ListGroupItem>
                    );
                }) : <ListGroupItem>No rooms available</ListGroupItem>
            }

        </ListGroup>
    );
}