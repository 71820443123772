import { store } from "app/store";
import { ChatMessage, messageAdded } from "features/chat/chat-slice";
import {
  MedicalDevices,
  MedicalDevicesMeasurements,
  medicalDevicesListUpdated,
  medicalDevicesMeasurementsUpdated,
} from "features/room/medicalDevices/medical-devices-slice";

import { StreamsByDevice } from "features/streams/streams-slice";


// Default transceivers are created when setting up the peer connection
const DEFAULT_TRANSCEIVERS: {
  device: keyof StreamsByDevice;
  kind: "audio" | "video";
}[] = [
  { device: "camera", kind: "audio" },
  { device: "camera", kind: "video" },
  { device: "instrument", kind: "video" },
  { device: "screen", kind: "video" },
];

// Datachannel options
const DATA_CHANNEL_OPTIONS: RTCDataChannelInit = {
  ordered: true, // guarantee order of messages
  //    maxPacketLifeTime: 1500,    // milliseconds (cannot have both maxRetransmits and maxPacketLifeTime)
  maxRetransmits: 10, // number of retransmits
  negotiated: true, // channel negotiated "out of band", through the unique id
  id: 0, // unique id for that channel, shared by both peers
};
export default class TelemedPeerConnection {
  // Constructor
  private peerConnection: RTCPeerConnection;
  private _dataChannel: RTCDataChannel;
  private iceConfiguration: RTCConfiguration;

  private _localStreams: { [device: string]: MediaStream } = {};
  private rtcRtpSenders: {
    [device: string]: {
      [kind: string]: RTCRtpSender;
    };
  } = {};

  private peerConnectionRemoteStreams: MediaStream[] = [];

  private _remoteStreams: { [device: string]: MediaStream } = {};

  // TODO: VERIFY THIS POINT: onDataChannelMessageCallback is required to avoid Typescript complaining about the callback not being set
  // It should be possible to set the callback after the constructor is called, and use a default callback if it is not set
  constructor(
    iceConfiguration: RTCConfiguration,
  ) {
    // Create the peerConnection
    console.debug("Creating TelemedPeerConnection");
    console.debug("iceConfiguration", iceConfiguration);

    // Save the iceConfiguration for later use (e.g. when resetting the peer connection)
    this.iceConfiguration = iceConfiguration;

    this.setupStreams();

    const peerConnection = this.newPeerConnection(iceConfiguration);
    const dataChannel = this.newDataChannel();

    this.peerConnection = peerConnection;
    this._dataChannel = dataChannel;
  }

  private newPeerConnection(
    iceConfiguration: RTCConfiguration,
  ): RTCPeerConnection {
    console.debug("Creating peerConnection");
    // Create the peerConnection
    let peerConnection = new RTCPeerConnection(iceConfiguration);

    console.debug("New peerConnection created. Setting up...");

    // Register listeners for the peerConnection
    this.registerConsoleLogListeners(peerConnection);
    this.registerFunctionalListeners(peerConnection);

    // Initialise the streams that will be used to group and identify tracks sent to the peerConnection
    // Then add transceivers to the peerConnection for future tracks
    //this.setupStreamsAndTransceivers(peerConnection);
    this.setupTransceivers(peerConnection);

    peerConnection.addEventListener("track", this.onTrack);

    console.debug("New peerConnection created and set up.");

    this.peerConnection = peerConnection;
    console.debug("peerConnection", peerConnection);


    // Need to return both the peerConnection and the dataChannel
    // for the constructor to work...
    return peerConnection;
  }

  private newDataChannel() : RTCDataChannel {
    // Create the data channel
    console.debug("Peer connection set up. Creating dataChannel...");
    let dataChannel = this.peerConnection.createDataChannel(
      "dataChannel",
      DATA_CHANNEL_OPTIONS
    );
    this.addDataChannelListener(dataChannel);

    console.debug("New dataChannel created and set up.");

    this._dataChannel = dataChannel;
    console.debug("dataChannel", dataChannel);

    return dataChannel;
  }


  // Handle the track event
  private onTrack = (event: RTCTrackEvent) => {
    console.debug("Track event");
    console.debug("Track event transceiver", event.transceiver);

    // Add the new stream to the list of remote streams (if it is not already there)
    if (!this.peerConnectionRemoteStreams.includes(event.streams[0]))
      this.peerConnectionRemoteStreams.push(event.streams[0]);

  };

  // Expose streams
  get localStreams() {
    return this._localStreams;
  }

  get remoteStreams() {
    return this._remoteStreams;
  }

  // Callbacks
  public sendDescriptionCallback = (
    description: RTCSessionDescription
  ): void => {
    throw new Error("sendDescriptionCallback not set");
  };

  public sendCandidateCallback = (candidate: RTCIceCandidate): void => {
    throw new Error("sendCandidateCallback not set");
  };

  public setMakingOfferCallback = (makingOffer: boolean): void => {
    throw new Error("setMakingOfferCallback not set");
  };


  // Generic callback to add an event listener to the peerConnection
  // public addEventListener = (event: string, callback: (...args: any) => void) => {
  //     this.peerConnection.addEventListener(event, callback);
  // }

  public onIceCandidateCallback = (event: RTCPeerConnectionIceEvent) => {
    console.warn("onIceCandidateCallback not set");
  };

  public onConnectionStateChangeCallback = (event: Event) => {
    console.warn("onConnectionStateChangeCallback not set");
  };

  public onIceGatheringStateChangeCallback = (event: Event) => {
    console.warn("onIceGatheringStateChangeCallback not set");
  };

  public onIceConnectionStateChangeCallback = (event: Event) => {
    console.warn("onIceConnectionStateChangeCallback not set");
  };

  public onSignalingStateChangeCallback = (event: Event) => {
    console.warn("onSignalingStateChangeCallback not set");
  };

  // Getters for peerConnection states
  get iceGatheringState() {
    return this.peerConnection.iceGatheringState;
  }
  get connectionState() {
    return this.peerConnection.connectionState;
  }
  get signalingState() {
    return this.peerConnection.signalingState;
  }
  get iceConnectionState() {
    return this.peerConnection.iceConnectionState;
  }

  // RTCPeerConnection specific features
  public restartIce = async () => {
    // console.debug("Restarting ICE");
    // this.peerConnection.setLocalDescription(
    //   await this.peerConnection.createOffer({ iceRestart: true })
    // );
    // this.peerConnection.restartIce();
    this.handleNegotiationNeededEvent.bind(this)();
  };

  // get localDescription() { return this.peerConnection.localDescription; }
  public getLocalDescription: () => RTCSessionDescription | null = () => {
    // undefined --> null
    return this.peerConnection.localDescription;
  };
  // get signaling state
  public getSignalingState: () => RTCSignalingState | undefined = () => {
    return this.peerConnection.signalingState;
  };

  // add remoteIce Candidate to the peerConnection
  public addIceCandidate = async (candidate: RTCIceCandidate) => {
    return this.peerConnection.addIceCandidate(candidate);
  };

  // Register console logs for the peerConnection events
  private registerConsoleLogListeners = (peerConnection: RTCPeerConnection) => {
    console.debug(
      "Registering console log listeners for peerConnection events"
    );
    //this.peerConnection.addEventListener("icecandidate", (event) => console.debug("ICE candidate:", event.candidate));
    //this.peerConnection.addEventListener("icecandidateerror", (event) => console.debug("ICE candidate error:", (event as RTCPeerConnectionIceErrorEvent).errorText));
    peerConnection.addEventListener("iceconnectionstatechange", (event) => {
      console.debug(
        `ICE connection state changed: ${
          (event.target as RTCPeerConnection).iceConnectionState
        }`
      );
      this.onIceConnectionStateChangeCallback(event);
    });
    peerConnection.addEventListener("icegatheringstatechange", (event) => {
      console.debug(
        `ICE gathering state changed: ${
          (event.target as RTCPeerConnection).iceGatheringState
        }`
      );
      this.onIceGatheringStateChangeCallback(event);
    });
    peerConnection.addEventListener("signalingstatechange", (event) => {
      console.debug(
        `Signaling state changed: ${
          (event.target as RTCPeerConnection).signalingState
        }`
      );
      this.onSignalingStateChangeCallback(event);
    });
    peerConnection.addEventListener("connectionstatechange", (event) => {
      console.debug(
        `Connection state change: ${
          (event.target as RTCPeerConnection).connectionState
        }`
      );

      if (
        (!this._dataChannel || (this._dataChannel.readyState === "closed")) &&
          (peerConnection &&
          peerConnection.connectionState &&
          peerConnection.connectionState === "connected")
      ) {
        console.debug("Creating new dataChannel after (re)connection");
        this.newDataChannel();
      }

      this.onConnectionStateChangeCallback(event);

    });

    peerConnection.addEventListener("negotiationneeded", (event) =>
      console.debug("Negotiation needed event")
    );
    peerConnection.addEventListener("track", (event: RTCTrackEvent) =>
      console.debug("Track event:", event.track)
    );
    peerConnection.addEventListener(
      "datachannel",
      (event: RTCDataChannelEvent) =>
        console.debug("Data channel received from remote peer", event.channel)
    );
  };


  // Register functional listeners for the peerConnection events
  private registerFunctionalListeners = (peerConnection: RTCPeerConnection) => {
    console.debug("Registering functional listeners for peerConnection events");

    // Detects if the peerConnection is disconnected or failed, and restarts ICE
    peerConnection.addEventListener("iceconnectionstatechange", (event) => {
      if (
        peerConnection &&
        peerConnection.iceConnectionState &&
        ["failed", "disconnected"].includes(peerConnection.iceConnectionState)
      ) {
        peerConnection.restartIce();
      }
    });

    // New ICE candidate
    peerConnection.addEventListener("icecandidate", (event) => {
      if (event.candidate) {
        // Send the ICE candidate to the signaling channel
        this.sendCandidateCallback(event.candidate);
      }

      this.onIceCandidateCallback(event);
    });

    // Negotiation Needed
    peerConnection.addEventListener(
      "negotiationneeded",
      this.handleNegotiationNeededEvent.bind(this)
    );
  };

  private async handleNegotiationNeededEvent (event?: Event){
    try {
      // "Perfect negotiation"
      // Setting makingOffer to true so that the "polite peer" will refuse offers from the other peer while the localDescription is being set
      // makingOffer is set to false in the offerUpdated function
      this.setMakingOfferCallback(true);
      // Set the local description, and send an offer to the other peer
      await this.setLocalDescription();
      this.sendDescriptionCallback(this.peerConnection.localDescription!);
      console.debug(
        "Negotiation needed event: sending new offer to the other peer"
      );
    } catch (err) {
      console.error(err);
    } finally {
      // This piece of code from the original example is handled
      // by the sendDescriptionCallback function of the signaling channel
      // makingOffer = false;
    }
  };

  public resetPeerConnection = () => {
    console.warn("Resetting peerConnection");
    this.peerConnection.close();
    this.newPeerConnection(
      this.iceConfiguration,
    );
  };

  public replaceDeviceStream = (
    stream: MediaStream,
    device: keyof StreamsByDevice
  ) => {
    if (!this.rtcRtpSenders[device]) {
      console.error("No RTCRtpSender found for device", device);
      return;
    }

    // Go through each track of the stream
    const tracks = stream.getTracks();
    for (const track of tracks) {
      // Replace the track in the right RTCRtpSender
      const sender = this.rtcRtpSenders[device][track.kind];
      if (!sender) {
        console.warn(
          `No RTCRtpSender found device "${device}", track "${track.label}" (${track.kind})`,
          track
        );
      } else {
        sender.replaceTrack(track);
      }
    }
  };

  private setupStreams() {
    console.debug("Setting up dummy streams");
    // Create one empty stream per device found in DEFAULT_TRANSCEIVERS
    // MediaStreams are used to group and identify tracks sent to the peerConnection
    for (const { device } of DEFAULT_TRANSCEIVERS) {
      if (!this._localStreams[device])
        this._localStreams[device] = new MediaStream();
      if (!this._remoteStreams[device])
        this._remoteStreams[device] = new MediaStream();
    }

    console.debug(
      "TelemedPeerConnection: Placeholder MediaStreams created for each device"
    );
    console.debug("this._localStreams", this._localStreams);
    console.debug("this._remoteStreams", this._remoteStreams);
  }

  public setupTransceivers = (peerConnection: RTCPeerConnection) => {
    console.debug("Setting up dummy transceivers");

    // Add senders to the peer connection, for future local tracks
    for (const { device, kind } of DEFAULT_TRANSCEIVERS) {
      // Create Transceiver and add it to the peer connection
      const rtcRtpTransceiver = peerConnection.addTransceiver(kind, {
        streams: [this._localStreams[device]],
      });

      if (!rtcRtpTransceiver) {
        console.error(
          "Error creating transceiver",
          device,
          kind,
          this._localStreams[device]
        );
        throw new Error("Error creating transceiver for device");
      }
      // Store Transceiver locally (to enable the usage of replaceTrack later)
      if (!this.rtcRtpSenders[device]) this.rtcRtpSenders[device] = {};

      this.rtcRtpSenders[device][kind] = rtcRtpTransceiver.sender;
    }

    console.debug(
      "TelemedPeerConnection: Transceivers (senders) created for each device and kind"
    );
    console.debug(this.rtcRtpSenders);
  };

  // This function will be called when we receives an answer
  public setRemoteDescription = async (description: RTCSessionDescription) => {
    console.debug("setRemoteDescription", description);
    // Set the remote description
    return this.peerConnection.setRemoteDescription(description);
  };

  // Set local description
  public setLocalDescription = async (description?: RTCSessionDescription) => {
    console.debug("setLocalDescription", description);
    // Set the local description
    return this.peerConnection.setLocalDescription(description);
  };

  // DATA CHANNEL

  private addDataChannelListener = (dataChannel: RTCDataChannel) => {
    console.debug("Setting datachannel listeners");

    // Register listeners for the data channel
    dataChannel.addEventListener("message", (event: MessageEvent) => {
      this.handleDataChannelMessage(event);
    });
    dataChannel.addEventListener("open", (event) => {
      console.debug("Data channel is open and ready to be used.");
      if (
        this.peerConnection &&
        this.peerConnection.connectionState &&
        this.peerConnection.connectionState === "connected"
      ) {
        console.debug("Connected to remote peer, sending local streams to signaling");

        this.sendStreamsByDeviceThroughDataChannel(this._localStreams);
      }
    });

    dataChannel.addEventListener("close", (event) => {
      console.debug("The Data Channel is Closed", event.target);
      console.debug("this._dataChannel", this._dataChannel)
    });
  };

  public sendChatMessage = (message: ChatMessage) => {
    this.sendMessage("chatMessage", message);
  };

  public sendMedicalInstruments = (message: MedicalDevices) => {
    this.sendMessage("instrumentsList", message);
  };

  public sendMedicalDevicesMeasurements = (
    message: MedicalDevicesMeasurements
  ) => {
    this.sendMessage("instrumentsData", message);
  };


  // Send local streams through data channel
  private sendStreamsByDeviceThroughDataChannel = (streamsByDevice: StreamsByDevice) => {
    const localMediaStreamsByDevice: StreamsByDevice =
      Object.fromEntries(
        Object
          .entries(streamsByDevice)
          .map(([device, stream]) => {
            return [device, stream.id];
          })
      );
    this.sendMessage("streamsByDevice", localMediaStreamsByDevice);
  };

  private sendMessage = (type: string, payload: any) => {
    if (!this._dataChannel) {
      console.error(
        `Cannot send message of type ${type}: Data channel not initialised`
      );
      return;
    }
    if (this._dataChannel.readyState !== "open") {
      console.error(
        `Cannot send message of type ${type}: Data channel not open`
      );
      return;
    }

    const data = JSON.stringify({
      type: type,
      payload: payload,
    });

    this._dataChannel.send(data);

    console.debug(`Message sent to other peer through data channel: ${data}`);
  };



  // This function handles the different types of messages we can receive from the data channel and dispatch the appropriate action
  private handleDataChannelMessage(event: MessageEvent) {
    // Message          : {type: "chatMessage", payload: ChatMessage}
    // Stream           : {type: "stream", payload: {stream: MediaStream}}
    // Instruments List : {type: "instrumentsList", payload: MedicalDevice[]}
    // Instruments Data : {type: "instrumentsData", payload: MedicalDeviceMeasurement}

    const message = JSON.parse(event.data);
    console.debug("Message received from data channel", message, message.type)

    switch (message.type) {
      case "chatMessage":
        store.dispatch(messageAdded(message.payload));
        break;

      case "streamsByDevice":
        console.debug("Received streamsByDevice message", message.payload);

        this.handleStreamsByDeviceMessage(message.payload);

        break;
      
      // case "streamUpdate":
      //   console.debug("WIP", message.payload);
      //   Object.keys(message.payload).forEach((deviceType) => {
      //     console.debug("Device type", deviceType);
      //     console.debug("Stream", message.payload[deviceType]);
      //     console.debug("Stream ID", message.payload[deviceType].streamId);
      //     const newStreamDetails = {
      //       origin: "remote" as keyof StreamsState,
      //       deviceType: deviceType as keyof StreamsByDevice,
      //       streamDetails: {
      //         streamId: message.payload[deviceType].streamId,
      //       }
      //     };

      //     dispatch(streamUpdated(newStreamDetails));
      //   });
      //   break;

      case "instrumentsList":
        store.dispatch(medicalDevicesListUpdated(message.payload));
        break;
      case "instrumentsData":
        store.dispatch(medicalDevicesMeasurementsUpdated(message.payload));
        break;
      default:
        console.error("Unknown message type", message.type, message);
    }
  }


  private handleStreamsByDeviceMessage (streamsByDevice: {[device: string]: string }, ttl = 20) {
    if (ttl <= 0) {
      console.error("Timeout waiting for remote streams (handleStreamsByDeviceMessage)");
      console.debug("streamsByDevice", streamsByDevice);
      console.debug("this.peerConnectionRemoteStreams", this.peerConnectionRemoteStreams);
      return;
    }
    
    console.debug("Going to loop through this.peerConnectionRemoteStreams, lenght=" + this.peerConnectionRemoteStreams.length, this.peerConnectionRemoteStreams);
    
    if (this.peerConnectionRemoteStreams.length === 0) {
      console.debug("No remote streams yet, waiting for them to be received");

      this.handleStreamsByDeviceMessage(streamsByDevice, ttl - 1);
      return;
    }


    // Go through each peerConnectionRemoteStream, find the device type corresponding to that stream in the payload, and add its tracks to the right _remoteStream
    this.peerConnectionRemoteStreams.forEach((stream) => {
      const deviceType = Object.keys(streamsByDevice).find((deviceType) => {
        return streamsByDevice[deviceType] === stream.id;
      });
      if (deviceType) {
        console.debug("Found device type", deviceType);

        // remove all tracks from the corresponding _remoteStream
        this._remoteStreams[deviceType].getTracks().forEach((track) => {
          this._remoteStreams[deviceType].removeTrack(track);
        });

        console.debug("Removed all tracks from remote stream " + deviceType, this._remoteStreams[deviceType]);

        // add all tracks from the stream received in the payload
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          console.debug("Adding track", track);
          this._remoteStreams[deviceType].addTrack(track);
        });

        console.debug("Added all tracks from remote stream " + deviceType, this._remoteStreams[deviceType]);
      } else {
        // Handle reconnections
        this.handleStreamsByDeviceMessage(streamsByDevice, ttl - 1);
        return;
      }
    });
  };

}
