import { store } from "app/store";
import { supabase } from "app/supabaseClient";
import { roomListUpdated } from "features/room/room-list-slice";
import { roomIdUpdated } from "features/room/room-slice";


// Initialising the state
let state = store.getState();
// Subscribe to the store to get the updated state
store.subscribe(() =>
    state = store.getState()
)

export const updateRoomList = () => {
    // Thunk Function
    return async (dispatch: (arg0: { type: string; payload: any; }) => void, getState: any) => {
        console.debug("RoomBrowser - Updating rooms from supabase");
        try {
            const { data, error } = await supabase.rpc('get_all_rooms')

            if (data) {
                dispatch(roomListUpdated(data));
            }

            if (error) {
                console.error("Error getting rooms", error);
            }
        } catch (error) {
            console.error("Unexpected error getting rooms", error);
        }
    }
}

export const createRoom = () => {
    // Thunk Function
    return async (dispatch: (arg0: { type: string; payload: any; }) => void, getState: any) => {
        console.debug("Creating room in supabase");
        try {
            const { data, error } = await supabase.rpc('create_room');
            if (error) {
                console.error("Error creating room", error);
            }
            if (data) {
                dispatch(roomIdUpdated({id:data[0].id, short_name:data[0].short_name}));
            }
        } catch (error) {
            console.error("Unexpected error creating room", error);
        }
    }
}

export const deleteAllRoomsExceptCurrent = () => {
    // Thunk Function
    return async (dispatch: (arg0: { type: string; payload: any; }) => void, getState: any) => {
        console.debug("Deleting all rooms from supabase");
        try {
            
            const id = state.room.id;
            let returnVal;
            if (id)
                returnVal = await supabase.rpc('delete_rooms_except', { id: id });
            else
                returnVal = await supabase.rpc('delete_all_rooms');

            if (returnVal.error) {
                console.error("Error getting rooms", returnVal.error);
            }
        } catch (error) {
            console.error("Unexpected error getting rooms", error);
        }
    }

}

// NEED REVIEW
//Maybe doing a bigger slicer that does all the room stuff is better
//currently it doesn't work as it is in WIP
export const findRoomWithId = (id: string) => {
    // Thunk Function
    return async (dispatch: (arg0: { type: string; payload: any; }) => void, getState: any) => {
        console.debug("Finding room with id", id);
        try {
            //find the room in roomList with the corresponding ID
            const room = state.roomList.roomList.find((room) => room.id === id);
            console.log("room in the findRoom thunk", room);
            //const { data, error } = await supabase.rpc('get_room_with_id', { id: id });
            if (room) {
                dispatch(roomIdUpdated({id:room.id, short_name: room.short_name}));
            }
        } catch (error) {
            console.error("Unexpected error getting rooms", error);
        }
    }
}