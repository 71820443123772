import { useRef, useContext, useEffect } from "react";
// Redux store
import { useDispatch } from "react-redux";
import MediaStreamsContext from "context/MediaStreamsContext";
import { selectedStreamUpdated } from "features/streams/selected-stream-slice";
import { useAppSelector } from "app/hooks";

interface VideoThumbnailProps
  extends React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  > {
  stream?: MediaStream | null;
  streamid?: string;
}

const DefaultVideoThumbnailProps: VideoThumbnailProps = {
  streamid: "",
  muted: false,
  controls: false,
  autoPlay: true,
  style: { maxWidth: "100%" },
};

export function VideoThumbnail(props: VideoThumbnailProps) {
  const dispatch = useDispatch();

  const selectedStream = useAppSelector((state) => state.selectedStream.streamId);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mediaStreams] = useContext(MediaStreamsContext);

  const videoElementRef = useRef<HTMLVideoElement>(null);

  const videoProps = { ...DefaultVideoThumbnailProps, ...props };

  useEffect(() => {
    // function setStream() {
    if (videoElementRef.current && videoProps.streamid) {
      console.debug(`VideoThumbnail, streamId: ${videoProps.streamid}`, videoElementRef.current);

      if (mediaStreams[videoProps.streamid]) {
        videoElementRef.current.srcObject = mediaStreams[videoProps.streamid]
        // If there is no selected stream, set it
        // DIRTY, NEED TO FIND ANOTHER WAY TO DO THIS
        if (!selectedStream) dispatch(selectedStreamUpdated(videoProps.streamid));

      } else {
        console.error("VideoThumbnail, cannot set stream (not found in mediaStreams)");
        console.debug("videoElementRef.current", videoElementRef.current);
        console.debug("videoProps.streamid", videoProps.streamid);
        console.debug("mediaStreams[videoProps.streamid]", videoProps.streamid ? mediaStreams[videoProps.streamid] : null);
      }

    } else {
      console.debug("VideoThumbnail, cannot set stream (missing videoElementRef.current or videoProps.streamid)");
      console.debug("videoElementRef.current", videoElementRef.current);
      console.debug("videoProps.streamid", videoProps.streamid);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.streamid, mediaStreams]);

  function handleClick() {
    if (videoElementRef.current && videoProps.streamid) {
      console.debug("Clicked video element");
      dispatch(selectedStreamUpdated(videoProps.streamid));
      //setSelectedStream(videoProps.streamid);
    }
  }


  return (
    <video
      className="hover-pointer"
      {...videoProps}
      ref={videoElementRef}
      onClick={handleClick}
    />
  );
}
